<template>
<Banner title="Referenzen" bgImg="bg_code.jpg" />
<Referenzen />
<Footer />
</template>

<script>
import Banner from './components/Banner.vue';
import Footer from './components/Footer.vue';
import Referenzen from './components/Referenzen.vue';

export default {
    name: 'App',
    components: {
        Footer,
        Banner,
        Referenzen
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');

:root {
    --background-color: #1a3a6c;
    --background-color-rgb: 26, 58, 108;
    --background-color-tile-light: #bbc5d4;
    --background-color-tile-dark: #8d9db6;
}

html {
    background-color: var(--background-color);
}

body {
    margin: 0;
    font-family: Open Sans Condensed, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: rgba(255, 255, 255, .87);
}
</style>
