<template>
<div class="referenzen">
    <Referenz imgName="fv09" title="FV 09 Nürtingen" url="fv09.de" />
    <Referenz imgName="ben-fotodesign" title="Ben Fotodesign" url="ben-fotodesign.de" />
    <Referenz imgName="bst" title="bst" url="bst.ch" />
    <Referenz imgName="dr-lorch" title="Dr. Lorch" url="dr-lorch.de" />
    <Referenz imgName="drpeterneubauer" title="Dr. Peter Neubauer" url="drpeterneubauer.de" />
    <Referenz imgName="granitprofi" title="Granitprofi" url="granitprofi24.de" />
    <Referenz imgName="hkr" title="HKR" url="hkrweb.de" />
    <Referenz imgName="innovationstage" title="IHK Innovationstage" url="innovationstage.de" />
    <Referenz imgName="koerperwerk" title="Körperwerk" />
    <Referenz imgName="leben-am-see" title="Leben am See" url="leben-am-see.de" />
    <Referenz imgName="meyer-sports" title="Meyer Sports" url="meyer-sports.eu" />
    <Referenz imgName="mogadorshop" title="Mogadorshop" url="mogadorshop.de" />
    <Referenz imgName="osd-schaefer" title="OSD Schäfer" />
    <Referenz imgName="p57" title="P57" url="p57.de" />
</div>
</template>

<script>
import Referenz from './Referenz.vue';

export default {
    name: 'Referenzen',
    components: {
        Referenz
    }
}
</script>

<style scoped>
.referenzen {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 786px) {
    .referenzen {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
</style>
