<template>
<div class="background" :style="{ backgroundImage: `url(${bgImgPath})` }">
    <div class="overlay">
        <h2 class="title">{{title}}</h2>
    </div>
</div>
</template>

<script>
export default {
    name: 'Banner',
    props: {
        bgImg: String,
        title: String
    },
    setup(props) {
        const bgImgPath = require(`../assets/content/images/banner/${props.bgImg}`)
        return {
            bgImgPath
        }
    }
}
</script>

<style scoped>
.background {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.overlay {
    background-color: rgba(var(--background-color-rgb), 0.7);
    padding: 20px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
}

.title {
    font-size: 4em;
    letter-spacing: 5px;
    text-shadow: 3px 2px 5px rgba(var(--background-color-rgb), 1);
    text-transform: uppercase;
    line-height: 1.2em;
    font-weight: 400;
    color: #fff;
    margin: 0;
}
</style>
