<template>
<div class="referenz">
    <img :src="imgPath">
    <div class="tags">
        <span>{{title}}</span>
        <a target="_blank" v-if="url" :href="codedUrl">{{url}}</a>
    </div>
</div>
</template>

<script>
export default {
    name: 'Referenz',
    props: {
        url: String,
        imgName: String,
        title: String
    },
    setup(props) {
        const imgPath = require(`../assets/content/referenzen/${props.imgName}.png`)
        const codedUrl = `https://www.${props.url}`

        return {
            imgPath,
            codedUrl
        }
    }
}
</script>

<style scoped>
.referenz:nth-child(1),
.referenz:nth-child(3),
.referenz:nth-child(6),
.referenz:nth-child(8),
.referenz:nth-child(9),
.referenz:nth-child(11),
.referenz:nth-child(14) {
    background-color: var(--background-color-tile-light);

}

.referenz:nth-child(2),
.referenz:nth-child(4),
.referenz:nth-child(5),
.referenz:nth-child(7),
.referenz:nth-child(10),
.referenz:nth-child(12),
.referenz:nth-child(13) {
    background-color: var(--background-color-tile-dark);
}

@media only screen and (max-width: 786px) {

    .referenz:nth-child(4n+1),
    .referenz:nth-child(4n+4) {
        background-color: var(--background-color-tile-light);
    }

    .referenz:nth-child(4n+2),
    .referenz:nth-child(4n+3) {
        background-color: var(--background-color-tile-dark);

    }
}

img {
    width: 100%;
}

.tags {
    display: flex;
}

span {
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    padding: 7px 7px;
    background: rgba(var(--background-color-rgb), 0.8);
    color: #fff;
}

a {
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    padding: 7px 7px;
    background: #fff;
    color: rgba(var(--background-color-rgb), 0.8);
}
</style>
